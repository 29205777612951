import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import( '../views/index'),
    redirect:'Home',
    children:[
      {
        path: 'Home',
        name: 'Home',
        component: () => import( '../views/Home'),
      },
    
      {
        path: 'ProjectDisplay',
        name: 'ProjectDisplay',
        component: () => import( '../views/ProjectDisplay')
      },
      {
        path: 'TalentRecruitment',
        name: 'TalentRecruitment',
        component: () => import( '../views/TalentRecruitment')
      },
      {
        path: 'contactUs',
        name: 'contactUs',
        component: () => import( '../views/contactUs')
      },
  
    ]
  },
  
]

const router = new VueRouter({
  routes
})

export default router
